/* @charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  --shadow: color-mix(in lch, var(--blackberry), 95% transparent);
  --primary-transparent: color-mix(in lch, var(--primary), 90% transparent);
  --peach-transparent: color-mix(in lch, var(--peach), 90% transparent);
  display: grid;
  place-items: center;
  padding: clamp(12px, 10vmin, 96px);
  width: 100%;
  min-height: 100svh;
  background-color: var(--lychee);
  color: var(--blackberry);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem;
  border-radius: 1.2rem;
  background-color: var(--lychee-tint);
  box-shadow: 0 0.6rem 1.2rem 0 var(--shadow);
}
.card h4,
.card p {
  margin: 0;
  padding: 0;
}
.card form*/

.button-group { 
  display: flex;
  /* gap: 0.2rem; */
}

.icon,
button::before,
button::after {
  color: transparent;
  text-shadow: 0 0 lightgrey;
}

button {
  position: relative;
  display: flex;
  align-items: space-evenly;
  justify-content: space-evenly;
  /* gap: 0.6rem; */
  padding: 0.1rem 0.1rem;
  border: none;
  /* border-radius: 0.6rem; */
  /* color: black!important; */
  transition: background-color 0.3s, color 0.3s;
}
button::before, button::after {
  position: absolute;
  top: 10%;
  opacity: 0;
}
button:hover {
  /* background-color: #e86826 !important; */
  /* color: #fff !important; */
}
button:hover::before {
  left: 35%;
  animation: emote-one 0.8s cubic-bezier(0.7, 0.2, 0.6, 1) infinite;
}
button:hover::after {
  left: 60%;
  animation: emote-two 0.8s cubic-bezier(0.7, 0.2, 0.6, 1) infinite;
  animation-delay: 0.3s;
}

@keyframes emote-one {
  0% {
    transform: translate(0, 0) rotate(30deg);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, -4rem) rotate(-20deg);
    opacity: 0;
  }
}
@keyframes emote-two {
  0% {
    transform: translate(0, 0.25rem) rotate(-40deg);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, -3.25rem) rotate(10deg);
    opacity: 0;
  }
}
.good::before,
.good::after {
  content: "👍";
}

.love::before,
.love::after {
  content: "💜";
}

@keyframes emote-one-down {
  0% {
    transform: translate(0, 0) rotate(30deg);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 4rem) rotate(-20deg);
    opacity: 0;
  }
}
@keyframes emote-two-down {
  0% {
    transform: translate(0, -0.25rem) rotate(-40deg);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 3.25rem) rotate(10deg);
    opacity: 0;
  }
}
.bad::before,
.bad::after {
  content: "👎";
}

.bad:hover::before {
  top: 50%;
  animation-name: emote-one-down;
}

.bad:hover::after {
  top: 50%;
  animation-name: emote-two-down;
}

.button-group .selected {
    background-color: #e86826 !important;
    color: #fff !important;  
}

.feedback-input  {
    width:100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 1px solid #000;
    margin-top: 4px;
    min-height: 10px;
}

/* .feedback-input .MuiInputBase-input{
    padding: 1px 4px;
}; */

.feedback-container{
  max-width: 100vw;
}

@media only screen and (min-width: 768px) {
  .feedback-container{
    max-width: 500px;
  }
}
