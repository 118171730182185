@media only screen and (min-width: 899px) {

    .feedback-container{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .feedback-message-container-web{
        display:flex;
        max-width: 30%;
    }

    .feedback-message-container-web-text{ 
        font-weight: 300;
        /* font-size: 1.4rem; */
    }

    .feedback-message-container-mobile{
        display:none;
    }

    .feedback-card-container{
        width:50%;
    }

}

@media only screen and (max-width: 899px) {
    .feedback-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .feedback-message-container-web{
        display:none;
    }

    .feedback-message-container-mobile{
        display:flex;
    }

    .feedback-message-container-mobile-text{ 
        font-weight: 300;
        /* font-size: 15px; */
    }

    .feedback-card-container{
        width:'100%';
    }
}