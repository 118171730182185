
.order-history-card-container{
    width:100%;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 3px 3px 10px 4px #8080806e;
    border-radius: 10px;
    /* margin-bottom: 16px; */
    background-color: #ffffff5e;
}

@media (min-width: 768px) {
    .order-history-card-container{
        max-width:300px !important;
    }
}

