@media only screen and (max-width: 767px) {
    .MuiTableHead-root {
        display:none;
      }

      .MuiTableRow-root {
        width:70vw ;
      }

      .MuiTableContainer-root {
          width: 75vw!important ;
          min-width: unset;
      }
       .expander-row.MuiTableRow-root{
        display: inline-block;
        padding: 1rem 0.5rem 1rem 0.5rem;
        margin-top: 1.5rem;
        /* margin-left: 0.5rem;
        margin-right: 0.5rem; */
        border: 1px solid #ffffff55!important;
        box-shadow: inset 0 4px 10px rgb(0 0 0 / 20%);
        /* border-radius: 10px; */
        /* box-shadow: 0 0 10px; */
        width:75vw ;
        border-radius: 5px 5px 5px 5px;
        
      }
  
      .expandable-content{

        border: 1px solid #e86826;
        margin-left: 0.5rem;
        border: 1px solid #ffffff55!important;
        box-shadow: inset 0 -4px 10px rgb(0 0 0 / 20%);
        margin-right: 0.5rem;
        /* box-shadow: 0 0 10px; */
        width: 75vw!important;
        min-width: unset;
        border-radius: 5px 5px 5px 5px;
      }
  
      .MuiTableCell-root{
        display:block!important;
        align-items: flex-start!important;
        text-align:start!important;
      }

      .MuiTablePagination-selectLabel {
          display:none;
      }

      .MuiTablePagination-toolbar .MuiInputBase-root {
        display:none; 
      }

      .meal-list-table{
        display: none;
      }
}

@media only screen and (min-width: 767px) {
  .meal-list-card{
    display:none;
  }
}