body{
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Poppins, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	background : url('../img/login_bg.png');
	backdrop-filter: blur(40px);
	/* height: unset!important; */
	height:100%;
	position: fixed;
	overflow-y:scroll;
}

/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}
*/
::-webkit-scrollbar
{
	display:none;
	/* width: 5px;
	background-color: #F5F5F5; */
} 

/* ::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122,153,217)),
        color-stop(0.72, rgb(73,125,189)),
        color-stop(0.86, rgb(28,58,148)));
} */

._loading_overlay_wrapper{
	/* height:100vh; */
}
.glass-box{
	/* background: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
	/* backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px); */
	/* border: 1px solid rgba(255, 255, 255, 0.4); */
	border-radius: 10px;
	/* background-color:rgba( 255, 255, 255, 0.3 ); */
	background-color: #ffffff5e;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    /* box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.18 ); */
	/* box-shadow: 0px -3px 40px 3px #00000021; */
	box-shadow: 0px 0px 7px 1px #00000021;
	/* cursor: pointer; */
}

.rdrDefinedRangesWrapper{
	display:none;
}

.simplebar-content .MuiListItemButton-root.active{
	background: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	/* backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(10px); */
	border: 1px solid rgba(255, 255, 255, 0.4);
}

.header{
	flex-direction: row;
	justify-content: flex-start;
	background-color: #e86826;
	box-shadow: 4px 4px 10px 4px #e8682614;
	position: fixed;
	z-index: 2;
	top: 0;
	padding:4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.input-box-contained {
	margin-top: 16px!important;
	margin-bottom:16px!important;
}

.input-box-contained label {
	color: #000!important;
}

.input-box-contained input, fieldset{
	background: #ffffff40!important;
	border-radius: 10px;
	/* border: 2px solid #00000055; */
	box-shadow: 4px 4px 10px 4px #e8682614;
	color: #000!important;

}


.bottom-nav-bar .Mui-selected {
	background: #e86826;
	color: #fff;
}

