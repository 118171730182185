.fab-payment-container{
    position: fixed !important;
    bottom: 0;
    right: 0;
    z-index: 2;
    left:0;
    background-color: #e86826;
    padding:16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border-bottom: '1px solid #fff'; */
}

@media only screen and (min-width: 900px) {
    .fab-payment-container{
        display: none!important;
        position: fixed !important;
        bottom: 20px;
        right: 40%;
        left: 40%;
        z-index: 2;
        /* left:10px; */
        /* background-color: #e86826;
        padding:20px; */
        border-radius: 4px;
        width:20%;
        cursor:pointer;
    }
}

.shopping__icon:hover{
    background-color: #e86826!important;
}

.delivery-preference-select {
    width: 150px;
    border-color: #fff;
    padding:8px;
    border-radius: 8px;
}

.delivery-preference-select:visited{
    border-color: #fff;
}

.delivery-instruction-input{
    padding:8px;
    border: none !important;
    width:150px;
    border-radius: 8px;
}

.price-breakdown-discount{
    color: green;
}

.order-summary-container{
    padding: 10px;
    padding-bottom: 400px;
}

@media only screen and (min-width: 900px) {
    .order-summary-container{
        /* border: 1px solid green; */
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px -3px 40px 3px #00000021;
        padding-bottom: 40px!important;
    }
}

.order-summary-box{
    display: flex;
    height:100vh;
    padding-bottom:400px;
    overflow:scroll; 
    justify-content: flex-start;
    align-items: flex-start;
    /* box-shadow: 0px -3px 40px 3px #00000021; */
    /* border-radius:10px; */
}

@media only screen and (min-width: 900px) {
    .order-summary-box{
        padding:16px;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: unset; 

    }    
}