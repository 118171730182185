
.schedule-meal-apptContent .VerticalAppointment-textContainer{
    display: none;
}

.VerticalAppointment-time{
    display: none!important ;
}

.VerticalAppointment-title{
    white-space: pre-wrap!important;
}


.schedule-meal-appointment{
    display: flex;
    justify-content:  center!important ;
    align-items:  center!important ;
}

.MainLayout-relativeContainer .MuiTableCell-root.Cell-cell{
    height: 144px;
}

.MainLayout-ordinaryLeftPanelBorder{
    display: none!important;
}

.MuiPopover-root .Content-contentContainer{
    display: none!important;
}

.MuiPopover-root .MuiGrid-grid-xs-2{
    display:none !important;
}

.MuiPopover-root .MuiGrid-grid-xs-10{
    padding-left: 10px!important;
}
