
.shopping__icon {
    min-width: 25px!important;
    width: 25px!important;
    height: 25px!important;
    border-radius: 5px;
    background: #e86826;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.price_breakup_single{
    display: flex;
    flex-direction: row;
    padding-left:5%;
    padding-right:5%;
    color: #000;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
}

.category-list-container::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.category-list-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.service-list-container::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.service-list-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.filter-btn {
    /* border: 1px solid #000;
    padding: 20px 25px;
    background: transparent;
    margin-left: 15px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer; */
}

.active__btn>div {
    background: #e86826!important;
    font-size: 0.8rem;
    color: #fff;
}

.invalid-cart-item {
    /* box-shadow : inset 0 0 5px 1px red; */
    padding: 10px;
    width: 95%;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: inset 0 0 5px 1px red;
    margin-left: 2.5%;
    margin-right: 2.5%;
    border-radius: 10px;
}

.valid-cart-item {
    /* box-shadow : inset 0 0 5px 1px red; */
    /* box-shadow : inset 0 0 5px 1px grey; */
    padding: 10px;
    width: 95%;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 3px 3px 10px 4px #8080806e;
    margin-left: 2.5%;
    margin-right: 2.5%;
    border-radius: 10px;
}

@media only screen and (min-width: 900px) {
    .valid-cart-item{
        box-shadow: none!important;
    }

    .invalid-cart-item{
        box-shadow: none!important;
    }
}

.menu-date-picker {
    padding-top: 2px;
    padding-left: 10px;    
}

.menu-date-picker .MuiOutlinedInput-notchedOutline{
    border: none;
}


.menu-date-picker .MuiOutlinedInput-input{
    padding: 0;
    width: 60px;
    cursor: pointer!important; 
}

.menu-date-container > fieldset{
    border: none;
}

/* .menu-date-container{
    width: 90%!important;
} */

.fab-cart-container{
    position: fixed !important;
    /* bottom: 5%;
    right: 15%;
    left: 15%; */
    z-index: 9;
    bottom:0;
    right:0;
    left:0;
    background-color: #e86826;
    padding:16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border-radius: 10px; */
    /* border-bottom : '1px solid #fff'; */
}

@media only screen and (min-width: 900px) {
    .fab-cart-container{
        display: none!important;
        position: fixed !important;
        bottom: 30px;
        right: 35%;
        left: 35%;
        z-index: 2;
        border-radius: 4px;
        width:30%;
        cursor:pointer;
    }
}

.fab-menu-container{
    position: fixed !important;
    bottom: 20px;
    right: 10px;
    z-index: 2;
    border-radius: 4px;
}

@media only screen and (min-width: 900px) {
    .fab-menu-container{
        display: none!important;
    }
}


.menu-item-price-action{
    flex-direction: column!important;
    justify-content: center!important;
    background-color: #e86826;
    border-radius: 6px;
}

.menu-item-price-action button {
    color: #ffffff!important;
}

.menu-item-card-container{
    padding:10px;
    width:100%;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 3px 3px 10px 4px #8080806e;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #ffffff5e;
}

.menu-container{
    display: flex;
    height:100vh;
    padding-bottom:400px;
    overflow:scroll; 
    justify-content: flex-start;
    align-items: flex-start;
    /* box-shadow: 0px -3px 40px 3px #00000021; */
    /* border-radius:10px; */
}

@media only screen and (min-width: 900px) {
    .menu-container{
        padding:16px;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: unset; 

    }    
}

@media only screen and (max-width: 900px) {
    .menu-order-summary{
        display: none !important;
    }
}

.menu-order-summary{
    max-width: 30%;
}

.menu-order-summary .order-summary-container{
    /* background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur(10px); */
}

.menu-list-accordion-detail{
    padding:0px;
}

@media only screen and (min-width: 700px) {
    .menu-list-accordion-detail{
        padding:16px;
    }    
}

.menu-search-filter-toggle{
}

@media only screen and (min-width: 900px) {
    .menu-search-filter-toggle{
        display:none !important;
    }
}

.header-logo{
    padding-bottom:16px;
    padding-left:16px;
    padding-right:16px;
    margin-top:8px;
}

@media only screen and (max-width: 900px) {
    .header-logo{
        display: none !important;
    }
}


@media only screen and (min-width: 900px) {
    .menu-item-card-container{
        width:150px !important;
        border: none;
        box-shadow: none;
        background: transparent;
        /* padding:10px; */
        /* width:16vw;
        min-width: 100px; */
        /* border: 1px solid #e0e0e0;
        border-style: dashed;*/
        /* border-radius: 10px; 
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        box-shadow: 0 2px 4px 2px rgba( 0, 0, 0, 0.18 );
        background: rgba( 255, 255, 255, 0.3 ); */
    }

    
    .menu-item-card-main{
        display: none!important;
    }
}

@media only screen and (min-width: 1200px) {
    .menu-item-card-container{
        width: 180px !important;
    }
}


@media only screen and (max-width: 900px) {
    .menu-item-card-main-web{
        display:none!important;
    }    
}

.menu-item-card-main{
    /* flex-direction: column !important; */
    flex-direction: row-reverse !important;
    justify-content: space-between;
    /* justify-content: center; */
    min-height:100px;
}


.menu-item-card-main-web{
    display: flex;
    width: 175px;
    height: 250px;
    /* background: white; */
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0;
    transform: scale(0.95); 
    transition: box-shadow 0.5s, transform 0.5s;
}

.menu-item-card-main-web:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }
  .menu-item-card-main-web .container {
    width: 100%;
    height: 100%;
  }
  .menu-item-card-main-web .container .top {
    height:40%;
    /* height: 80%; */
    /* width: 100%; */
    /* background: url(https://s-media-cache-ak0.pinimg.com/736x/49/80/6f/49806f3f1c7483093855ebca1b8ae2c4.jpg) no-repeat center center; */
    /* -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%; */
    box-shadow: 0px 10px 13.3px 0px rgba(0, 0, 0, 0.50);
  }
  .menu-item-card-main-web .container .bottom {
    height:60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f4f4f4;
    /* padding-top:10px; */
    /* width: 200%; */
    /* height: 20%; */
    /* transition: transform 0.5s; */
    /* border-radius: 17px 17px 97px 17px; */
    /* border: 2px solid rgba(255, 255, 255, 0.10); */

    /* background: linear-gradient(180deg, rgba(128, 128, 128, 0.25) 0%, rgba(69, 69, 69, 0.50) 100%); */
  }
  .menu-item-card-main-web .container .bottom.clicked {
    transform: translateX(-50%);
  }
  .menu-item-card-main-web .container .bottom h1 {
    margin: 0;
    padding: 0;
  }
  .menu-item-card-main-web .container .bottom p {
    margin: 0;
    padding: 0;
  }
  .menu-item-card-main-web .container .bottom .left {
    /* height: 100%; */
    /* width: 50%; */
    background: #f4f4f4;
    position: relative;
    /* float: left; */
  }
  .menu-item-card-main-web .container .bottom .left .details {
    width:100%;
    padding: 20px;
    /* float: left; */
    /* width: calc(70% - 40px); */
  }
  .menu-item-card-main-web .container .bottom .left .buy {
    float: right;
    width: calc(30% - 2px);
    height: 100%;
    background: #f1f1f1;
    transition: background 0.5s;
    border-left: solid thin rgba(0, 0, 0, 0.1);
}
.menu-item-card-main-web .container .bottom .left .buy i {
    font-size: 30px;
    padding: 30px;
    color: #254053;
    transition: transform 0.5s;
}

.menu-item-card-main-web .container .bottom .left .buy:hover {
    background: #A6CDDE;
}

.menu-item-card-main-web .container .bottom .left .buy:hover i {
    transform: translateY(5px);
    color: #00394B;
}

.menu-item-card-main-web .container .bottom .right {
    width: 50%;
    background: #A6CDDE;
    color: white;
    float: right;
    height: 200%;
    overflow: hidden;
}

.menu-item-card-main-web .container .bottom .right .details {
    padding: 20px;
    float: right;
    width: calc(70% - 40px);
}

.menu-item-card-main-web .container .bottom .right .done {
    width: calc(30% - 2px);
    float: left;
    transition: transform 0.5s;
    border-right: solid thin rgba(255, 255, 255, 0.3);
    height: 50%;
}

.menu-item-card-main-web .container .bottom .right .done i {
    font-size: 30px;
    padding: 30px;
    color: white;
}

.menu-item-card-main-web .container .bottom .right .remove {
    width: calc(30% - 1px);
    clear: both;
    border-right: solid thin rgba(255, 255, 255, 0.3);
    height: 50%;
    background: #BC3B59;
    transition: transform 0.5s, background 0.5s;
}

.menu-item-card-main-web .container .bottom .right .remove:hover {
    background: #9B2847;
}

.menu-item-card-main-web .container .bottom .right .remove:hover i {
    transform: translateY(5px);
}

  .menu-item-card-main-web .container .bottom .right .remove i {
    transition: transform 0.5s;
    font-size: 30px;
    padding: 30px;
    color: white;
}

  .menu-item-card-main-web .container .bottom .right:hover .remove, .menu-item-card-main-web .container .bottom .right:hover .done {
    transform: translateY(-100%);
}

  .menu-item-card-main-web .inside {
    z-index: 9;
    background: #92879B;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;
}

  .menu-item-card-main-web .inside .icon {
    position: absolute;
    right: 85px;
    top: 85px;
    color: white;
    opacity: 1;
}

  .menu-item-card-main-web .inside:hover {
    width: 100%;
    right: 0;
    top: 0;
    border-radius: 0;
    height: 80%;
}

  .menu-item-card-main-web .inside:hover .icon {
    opacity: 0;
    right: 15px;
    top: 15px;
}

  .menu-item-card-main-web .inside:hover .contents {
    opacity: 1;
    transform: scale(1);
    transform: translateY(0);
}

  .menu-item-card-main-web .inside .contents {
    padding: 5%;
    opacity: 0;
    transform: scale(0.5);
    transform: translateY(-200%);
    transition: opacity 0.2s, transform 0.8s;
}

  .menu-item-card-main-web .inside .contents table {
    text-align: left;
    width: 100%;
}

  .menu-item-card-main-web .inside .contents h1, .menu-item-card-main-web .inside .contents p, .menu-item-card-main-web .inside .contents table {
    color: white;
}

.menu-item-card-main-web .inside .contents p {
    font-size: 13px;
}

.menu-item-display-name-web {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    word-wrap: 'normal';
    word-break: 'break-word';
    /* max-width:'90%'; */
}


.menu-item-card-detail{
    max-width: 61%;
}

.phonepe-payment-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.flexbox-wrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.flexbox-wrapper {
    display: flex;
    width:100%;
    /* overflow: auto; */
    min-height: 100%;
    /* height: 100vh; */
    /* height: calc(100vh - calc(100vh - 100%)); */
    /* max-height: 100vh;  */
    /* background-color: red; */
    flex-direction: column;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
    /* box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.18 ); */
    /* background: rgba( 255, 255, 255, 0.3 ); */
    /* background: url('static/background/bg1.png'); */
    /* background: url('../img/bg1.png'); */
    /* background-size: cover; */
    /* backdrop-filter: blur(10px); */
  
} 
  .sticky {
    position: -webkit-sticky; /* for Safari */
    position: fixed;
    /* top: 0; */
    align-self: flex-start; /* <-- this is the fix */
    background-color: rgba( 255, 255, 255, 0.3 );
    /* height:10vh; */
    width:100%;
    z-index: 2;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba( 255, 255, 255, 0.18 );
    /* box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.18 ); */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .addon-container{
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding:10px;
    /* margin:4px; */
  }

  .corporate-list-container{
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding:10px;
    margin-top:4px;
    margin-bottom:4px;
  }

  .customize-modal[role='dialog'] {
    background-color: '#f2f6fc';
  }  

  @media only screen and (min-width: 1200px) {
    .customize-modal[role='dialog'] {
        margin-left:100px;
        margin-right: 100px;
    } 
  } 

  .menu-icon-notification{

  }

  @media only screen and (max-width: 900px) {
    .menu-icon-notification{
        display: none !important;
    }
  }

  .menu-icon{
    padding:4px;
    border-radius:50px;
    height:36px;
    width: 36px;
    /* box-shadow: 0px 0px 5px 0px#1b030394; */
    display:flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }

  .change-qty-customization {
    min-width: 25px!important;
    width: 50px!important;
    height: 50px!important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.change-qty-customization-list {
    min-width: 25px!important;
    width: 25px!important;
    height: 25px!important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.search-menu-container>input{
    /* width: 100%;
    padding:10px;
    border-radius: 10px;
    margin:10px; */
    /* width:'100%'; */
    /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
    /* box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.18 ); */
    /* background: rgba( 255, 255, 255, 0.3 ); */
}

/* .search-menu-container>input:focus-visible{
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    box-shadow: 0 4px 8px 0 rgba( 0, 0, 0, 0.18 );
    background: rgba( 255, 255, 255, 0.3 );
} */

@media only screen and (min-width: 900px) {
    .search-menu-container{
        width: 30%!important;
    }
}

@media only screen and (min-width: 1200px) {
    .sticky{
        width: 70vw !important;
        /* width: calc(100% - 240px); */
        /* top:92px; */
    }
}

.menu-location-picker{
    margin-left:2px;
    margin-right:2px; 
    border-radius: 4px!important;
    /* width:180px; */
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: red; */
    align-items: center;
    cursor: pointer;
}


@media only screen and (max-width: 900px) {

    .upcoming-meal-btn{
        justify-content: flex-start!important;
    }
}

.upcoming-meal-btn{
    justify-content: flex-end;
}


.search-menu-input {
  /* border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px; */
  font-size: 14px;
  color: inherit;
  width:100%;
  /* border: 1px solid transparent; */
  border-radius: inherit;
  /* border: 1px solid #fff; */
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  background-color: #fff;
}


.search-menu-input::placeholder {
    color: #000;
}

.search-menu-input:focus {
    /* box-shadow: 0 0 2px 2px #fff; */
    /* border-color: #fff; */
    outline: none;
}

.input-transparent{
    font-size: 16px;
    line-height: 1.5;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border: none;
    background: rgba(255, 255, 255, 0.2);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23000000' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    border-radius: 5px;
    width: 100%;
    padding: .5em 1em .5em 2.5em;
    margin-bottom: 0px;
} 

.input-transparent::placeholder{
    color: #000;
}

.input-transparent:focus {
    outline: none;
    background: rgba(255, 255, 255, 1);
    text-shadow: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%238C92A0' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
background-position: 10px 10px;
background-size: 20px 20px;
}
    

.search-menu-form {
    color: #000;
    display: flex;
    padding: 2px;
    /* border: 1px solid #858585; */
    border-radius: 8px;
    margin: 10px 10px 10px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}


.scrolling-wrapper-flexbox {
    height: 56px;
    padding-left:4px;
    padding-right:4px;
    padding-top: 12px;
    padding-bottom: 12px;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    width: 100%;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
}


.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

@media only screen and (min-width: 900px) {
    .scrolling-wrapper-flexbox {
        display: none !important;
    }
}


.scrolling-wrapper-flexbox-web {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
}


.scrolling-wrapper-flexbox-web {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

@media only screen and (max-width: 900px) {
    .scrolling-wrapper-flexbox-web {
        display: none !important;
    }
}
  
.scrolling-wrapper-card {
    min-width: 75px;
    max-width: 100%;
    height: 32px;
    background: rgba(255, 255, 255, 0.5);
    color: #000;
    /* background-color: #fff; */
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    margin-right: 10px;
    margin-left:10px;
    line-height: 1.5;
    text-shadow: 0px 1px 1px rgba(175, 169, 169, 0.2);
    border: none;
    padding-left:10px;
    padding-right: 10px;
    cursor:pointer;
}

.scrolling-wrapper-card.active {
    /* background: #f0f0f0;
    color: #000; */
    background: #e86826;
    color: #fff;
}   
  
.scrolling-wrapper-flexbox-web .scrolling-wrapper-card.active {
    background: #e86826;
    color: #fff;
}


.scrolling-wrapper-flexbox-web .scrolling-wrapper-card{
    background-color: #f0f0f0;
    color: #000;
}

@media only screen and (max-width: 900px) {
    .navbar-web {
        display: none !important;
    }

    .navbar-mobile {
        display: flex !important;
    }
}



@media only screen and (min-width: 900px) {
    .navbar-mobile {
        display: none !important;
    }

    .navbar-web{
        display: flex !important;
    }
}


.menu-category-accordion{
    /* background-color: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur(10px); */
}
